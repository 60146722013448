export const loader = `${process.env.REACT_APP_IMAGE_URL}/loader.gif`;
export const logo = `${process.env.REACT_APP_IMAGE_URL}/blastLogoMobile.svg`;
export const profile = `${process.env.REACT_APP_IMAGE_URL}/profile-icon.svg`;
export const toggleOnIcon = `${process.env.REACT_APP_IMAGE_URL}/toggleOnCircle.svg`;
export const toggleOffIcon = `${process.env.REACT_APP_IMAGE_URL}/toggleOffCircle.svg`;
export const loginFrameLogo = `${process.env.REACT_APP_IMAGE_URL}/loginBackgroundImage.png`;
export const blastLogo = `${process.env.REACT_APP_IMAGE_URL}/blastLogo.svg`;
export const textHighlight = `${process.env.REACT_APP_IMAGE_URL}/backgroundColorImageLogin.svg`;
export const closeIcon = `${process.env.REACT_APP_IMAGE_URL}/close.svg`;
export const dishImage = `${process.env.REACT_APP_IMAGE_URL}/dishImg.png`;
export const dishImage2 = `${process.env.REACT_APP_IMAGE_URL}/dishImage2.png`;
export const dishImage3 = `${process.env.REACT_APP_IMAGE_URL}/dishImage3.png`;
export const boiledEggAllergen = `${process.env.REACT_APP_IMAGE_URL}/boiled-egg.svg`;
export const peanutAllergen = `${process.env.REACT_APP_IMAGE_URL}/peanut.svg`;
export const prawnAllergen = `${process.env.REACT_APP_IMAGE_URL}/prawn.svg`;
export const fullBuffetImg = `${process.env.REACT_APP_IMAGE_URL}/fullService.svg`;
export const dropOffImg = `${process.env.REACT_APP_IMAGE_URL}/dropoff.svg`;
export const bgImagePackages = `${process.env.REACT_APP_IMAGE_URL}/bgImagePackages.svg`;
export const dinner2 = `${process.env.REACT_APP_IMAGE_URL}/dinner2.png`;
export const foodContainer1 = `${process.env.REACT_APP_IMAGE_URL}/food-container-1.png`;
export const drink = `${process.env.REACT_APP_IMAGE_URL}/drink.svg`;
export const dessertImg = `${process.env.REACT_APP_IMAGE_URL}/dessert.svg`;
export const dinner = `${process.env.REACT_APP_IMAGE_URL}/dinner.svg`;
export const peopleOutline = `${process.env.REACT_APP_IMAGE_URL}/people-outline.svg`;
export const vector = `${process.env.REACT_APP_IMAGE_URL}/vector.svg`;
export const newIcon = `${process.env.REACT_APP_IMAGE_URL}/new.svg`;
export const infoIcon = `${process.env.REACT_APP_IMAGE_URL}/infoIcon.svg`;
export const warningIcon = `${process.env.REACT_APP_IMAGE_URL}/warningIcon.svg`;
export const visaLogo = `${process.env.REACT_APP_IMAGE_URL}/visa.svg`;
export const visaLogoWhite = `${process.env.REACT_APP_IMAGE_URL}/visa-white.svg`;
export const mastercardLogo = `${process.env.REACT_APP_IMAGE_URL}/mastercard.svg`;
export const mastercardLogoWhite = `${process.env.REACT_APP_IMAGE_URL}/mastercard-white.svg`;
export const mastercardLogo1 = `${process.env.REACT_APP_IMAGE_URL}/mastercard-1.svg`;
export const visaLogo1 = `${process.env.REACT_APP_IMAGE_URL}/visa-1.svg`;
export const amexLogo = `${process.env.REACT_APP_IMAGE_URL}/amex.svg`;
export const amexLogoWhite = `${process.env.REACT_APP_IMAGE_URL}/amex-white.svg`;
export const locationIcon = `${process.env.REACT_APP_IMAGE_URL}/location.png`;
export const callIcon = `${process.env.REACT_APP_IMAGE_URL}/call.png`;
export const printerIcon = `${process.env.REACT_APP_IMAGE_URL}/printer.png`;
export const foodPlate = `${process.env.REACT_APP_IMAGE_URL}/foodPlate.svg`;
export const contactUs = `${process.env.REACT_APP_IMAGE_URL}/contactUS.svg`;
export const toggleNavbar = `${process.env.REACT_APP_IMAGE_URL}/toggleNavbar.svg`;
export const emptyMenu = `${process.env.REACT_APP_IMAGE_URL}/emptyMenu.svg`;
export const minusIcon = `${process.env.REACT_APP_IMAGE_URL}/minusIcon.svg`;
export const addIcon = `${process.env.REACT_APP_IMAGE_URL}/addIcon.svg`;
export const packageBackground = `${process.env.REACT_APP_IMAGE_URL}/packageBG.svg`;
export const arrowUpIcon = `${process.env.REACT_APP_IMAGE_URL}/arrow-up.svg`;
export const arrowDownIcon = `${process.env.REACT_APP_IMAGE_URL}/vector.svg`;
export const noteIcon = `${process.env.REACT_APP_IMAGE_URL}/note.svg`;
export const deliveryIcon = `${process.env.REACT_APP_IMAGE_URL}/locationIcon.svg`;
export const userTagIcon = `${process.env.REACT_APP_IMAGE_URL}/userTag.svg`;
export const mailIcon = `${process.env.REACT_APP_IMAGE_URL}/mailIcon.svg`;
export const leftArrowIcon = `${process.env.REACT_APP_IMAGE_URL}/arrow-left.svg`;
export const orderSuccessfulImg = `${process.env.REACT_APP_IMAGE_URL}/order-successful.svg`;
export const bgImgThankYouCard = `${process.env.REACT_APP_IMAGE_URL}/bgImgThankyouCard.svg`;
export const callDark = `${process.env.REACT_APP_IMAGE_URL}/callDark.svg`;
export const phoneIcon = `${process.env.REACT_APP_IMAGE_URL}/call.svg`;
export const editIcon = `${process.env.REACT_APP_IMAGE_URL}/editIcon.svg`;
export const calendarIcon = `${process.env.REACT_APP_IMAGE_URL}/calendar.svg`;
export const handIcon = `${process.env.REACT_APP_IMAGE_URL}/handIcon.svg`;
export const infoCircle = `${process.env.REACT_APP_IMAGE_URL}/infoCircle.svg`;
export const notFound = `${process.env.REACT_APP_IMAGE_URL}/not-found.svg`;
export const rateUSbg = `${process.env.REACT_APP_IMAGE_URL}/rateUSbg.svg`;
export const arrowRight = `${process.env.REACT_APP_IMAGE_URL}/arrow-right.svg`;
export const starBlank = `${process.env.REACT_APP_IMAGE_URL}/star-blank.svg`;
export const shoppingCart = `${process.env.REACT_APP_IMAGE_URL}/shopping-cart.svg`;
export const shoppingCartSolid = `${process.env.REACT_APP_IMAGE_URL}/shopping-cart-solid.svg`;
export const mapPinMarker = `${process.env.REACT_APP_IMAGE_URL}/mapPinMarker.svg`;
export const defaultImage = `${process.env.REACT_APP_IMAGE_URL}/default-image.png`;
export const appetizers = `${process.env.REACT_APP_IMAGE_URL}/appetizer-lightbite.svg`;
export const bakeryBites = `${process.env.REACT_APP_IMAGE_URL}/bakery-bites.svg`;
export const desserts = `${process.env.REACT_APP_IMAGE_URL}/desserts.svg`;
export const hotMain = `${process.env.REACT_APP_IMAGE_URL}/hot-main.svg`;
export const hotSide = `${process.env.REACT_APP_IMAGE_URL}/hot-side.svg`;
export const package1 = `${process.env.REACT_APP_IMAGE_URL}/package1.svg`;
export const package2 = `${process.env.REACT_APP_IMAGE_URL}/package2.svg`;
export const package3 = `${process.env.REACT_APP_IMAGE_URL}/package3.svg`;
export const calendar = `${process.env.REACT_APP_IMAGE_URL}/calendarIcon.svg`;
export const successIcon = `${process.env.REACT_APP_IMAGE_URL}/successIcon.svg`;
export const rightArrow = `${process.env.REACT_APP_IMAGE_URL}/right-arrow.svg`;
export const tickSquare = `${process.env.REACT_APP_IMAGE_URL}/tick-square.svg`;
export const tickSquareActive = `${process.env.REACT_APP_IMAGE_URL}/tickSquareActive.svg`;
export const celeryIcon = `${process.env.REACT_APP_IMAGE_URL}/celery.svg`;
export const crustaceansIcon = `${process.env.REACT_APP_IMAGE_URL}/crustaceans.svg`;
export const eggsIcon = `${process.env.REACT_APP_IMAGE_URL}/eggs.svg`;
export const fishIcon = `${process.env.REACT_APP_IMAGE_URL}/fish.svg`;
export const lupinIcon = `${process.env.REACT_APP_IMAGE_URL}/lupin.svg`;
export const milkIcon = `${process.env.REACT_APP_IMAGE_URL}/milk.svg`;
export const molluscusIcon = `${process.env.REACT_APP_IMAGE_URL}/molluscs.svg`;
export const mustardIcon = `${process.env.REACT_APP_IMAGE_URL}/mustard.svg`;
export const nutsIcon = `${process.env.REACT_APP_IMAGE_URL}/nuts.svg`;
export const peanutsIcon = `${process.env.REACT_APP_IMAGE_URL}/peanuts.svg`;
export const sesameseedsIcon = `${process.env.REACT_APP_IMAGE_URL}/sesameseeds.svg`;
export const soyaIcon = `${process.env.REACT_APP_IMAGE_URL}/soya.svg`;
export const sulphurIcon = `${process.env.REACT_APP_IMAGE_URL}/sulphur-dioxide.svg`;
export const trashOutlineIcon = `${process.env.REACT_APP_IMAGE_URL}/trash.svg`;
export const blastIcon = `${process.env.REACT_APP_IMAGE_URL}/blastIcon.svg`;
export const qrcodeBackgroundTexture = `${process.env.REACT_APP_IMAGE_URL}/qrcodebackgroundtexture.svg`;
export const blastLogoQr = `${process.env.REACT_APP_IMAGE_URL}/blastLogoQr.svg`;
export const infoCircleIcon = `${process.env.REACT_APP_IMAGE_URL}/info-circle.svg`;
export const blackLocationIcon = `${process.env.REACT_APP_IMAGE_URL}/location-black.svg`;
export const ellipsesIcon = `${process.env.REACT_APP_IMAGE_URL}/ellipses.svg`;
export const explorePackagesImage = `${process.env.REACT_APP_IMAGE_URL}/explorePackages.svg`;
export const choosePackage = `${process.env.REACT_APP_IMAGE_URL}/chooseYourPackage.svg`;
export const secureCheckout = `${process.env.REACT_APP_IMAGE_URL}/secureCheckout.svg`;
export const customizeMenu = `${process.env.REACT_APP_IMAGE_URL}/customizeMenu.svg`;
export const warningCircle = `${process.env.REACT_APP_IMAGE_URL}/warning-circle.svg`;
export const browseMenu = `${process.env.REACT_APP_IMAGE_URL}/browseMenu.svg`;
export const customizeOrder = `${process.env.REACT_APP_IMAGE_URL}/customizeOrder.svg`;
export const checkoutDelivery = `${process.env.REACT_APP_IMAGE_URL}/checkoutDelivery.svg`;
export const dishFS1 = `${process.env.REACT_APP_IMAGE_URL}/dishFS1.webp`;
export const dishFS2 = `${process.env.REACT_APP_IMAGE_URL}/dishFS2.webp`;
export const dishFS3 = `${process.env.REACT_APP_IMAGE_URL}/dishFS3.webp`;
export const dishFS4 = `${process.env.REACT_APP_IMAGE_URL}/dishFS4.webp`;
export const dishFS5 = `${process.env.REACT_APP_IMAGE_URL}/dishFS5.webp`;
export const dishFS6 = `${process.env.REACT_APP_IMAGE_URL}/dishFS6.webp`;
export const dishFS7 = `${process.env.REACT_APP_IMAGE_URL}/dishFS7.webp`;
export const dishFS8 = `${process.env.REACT_APP_IMAGE_URL}/dishFS8.webp`;
export const dishFS9 = `${process.env.REACT_APP_IMAGE_URL}/dishFS9.webp`;
export const dishFS10 = `${process.env.REACT_APP_IMAGE_URL}/dishFS10.webp`;
export const dishFS11 = `${process.env.REACT_APP_IMAGE_URL}/dishFS11.webp`;
export const dishFS12 = `${process.env.REACT_APP_IMAGE_URL}/dishFS12.webp`;
export const dishDC1 = `${process.env.REACT_APP_IMAGE_URL}/dishDC1.webp`;
export const dishDC2 = `${process.env.REACT_APP_IMAGE_URL}/dishDC2.webp`;
export const dishDC3 = `${process.env.REACT_APP_IMAGE_URL}/dishDC3.webp`;
export const dishDC4 = `${process.env.REACT_APP_IMAGE_URL}/dishDC4.webp`;
export const dishDC5 = `${process.env.REACT_APP_IMAGE_URL}/dishDC5.webp`;
export const dishDC6 = `${process.env.REACT_APP_IMAGE_URL}/dishDC6.webp`;
export const clientPVH = `${process.env.REACT_APP_IMAGE_URL}/client_PVH.svg`;
export const clientAud = `${process.env.REACT_APP_IMAGE_URL}/client_aud.svg`;
export const clientDeliveroo = `${process.env.REACT_APP_IMAGE_URL}/client_deliveroo.svg`;
export const clientDevex = `${process.env.REACT_APP_IMAGE_URL}/client_devex.svg`;
export const clientPepsiCo = `${process.env.REACT_APP_IMAGE_URL}/client_PepsiCo.svg`;
export const clientBoehringer = `${process.env.REACT_APP_IMAGE_URL}/client_Boehringer.svg`;
export const clientMuseum = `${process.env.REACT_APP_IMAGE_URL}/client_Museum.svg`;
export const clientBCG = `${process.env.REACT_APP_IMAGE_URL}/client_BCG.svg`;
export const clientSnapchat = `${process.env.REACT_APP_IMAGE_URL}/client_snapchat.svg`;
export const clientPWC = `${process.env.REACT_APP_IMAGE_URL}/client_PWC.svg`;
export const docDownload = `${process.env.REACT_APP_IMAGE_URL}/document-download.svg`;
export const clientLogosCollage = `${process.env.REACT_APP_IMAGE_URL}/client_logos_collage.webp`;
export const dishCateringLogo = `${process.env.REACT_APP_IMAGE_URL}/dishcatering.png`;
export const dishCateringLogoHiRes = `${process.env.REACT_APP_IMAGE_URL}/dishiscateringhires2.png`;
export const dishCateringLogoHiRes2 = `${process.env.REACT_APP_IMAGE_URL}/dishcateringhires.png`;
export const dishCateringLogoHiRes3 = `${process.env.REACT_APP_IMAGE_URL}/dishcateringlogohires3.jpg`;
export const docBannerImage = `${process.env.REACT_APP_IMAGE_URL}/docBannerImage.webp`;
export const fscBannerImage = `${process.env.REACT_APP_IMAGE_URL}/fscBannerImage.webp`;
export const circleTopUpIcon = `${process.env.REACT_APP_IMAGE_URL}/circle-top-up-icon.svg`;
export const dropoffHeaderIcon = `${process.env.REACT_APP_IMAGE_URL}/dropoff-header-icon.svg`;
export const appetizerBg = `${process.env.REACT_APP_IMAGE_URL}/featured/menu-col-bg-1.png`;
export const Sandwich = `${process.env.REACT_APP_IMAGE_URL}/featured/Sandwich.webp`;
export const dessetBg = `${process.env.REACT_APP_IMAGE_URL}/featured/menu-col-bg-3.png`;
export const bakeryBiteBg = `${process.env.REACT_APP_IMAGE_URL}/featured/menu-col-bg-4.png`;
export const sidesBg = `${process.env.REACT_APP_IMAGE_URL}/featured/menu-col-bg-5.png`;
export const signatureCat = `${process.env.REACT_APP_IMAGE_URL}/featured/Signature-Boxes.webp`;
export const breakfastCat = `${process.env.REACT_APP_IMAGE_URL}/featured/1.webp`;
export const lunchCat = `${process.env.REACT_APP_IMAGE_URL}/featured/2.webp`;
export const breakCat = `${process.env.REACT_APP_IMAGE_URL}/featured/3.webp`;
export const beverageCat = `${process.env.REACT_APP_IMAGE_URL}/featured/Beverages.webp`;
export const bgOverlayEl = `${process.env.REACT_APP_IMAGE_URL}/bg_overlay_el.svg`;
export const checkmarkUrl = `${process.env.REACT_APP_IMAGE_URL}/checkmark.svg`;
export const linkIcon = `${process.env.REACT_APP_IMAGE_URL}/link.svg`;

export const emptyCartHero = `${process.env.REACT_APP_IMAGE_URL}/man_holding_empty_cart.svg`;
