import { Space, space } from './space';

export const FontTShirtSizes = ['3xs', '2xs', 'xs', 's', 'm', 'l', 'xl', '2xl', '3xl', '4xl', '5xl'] as const;

export type FontSizes = typeof FontTShirtSizes[number];

export interface ThemeInterface {
  primaryColor: Record<string, string>;
  space: Space;
  fontSize: Map<FontSizes, string>;
  fontFamily: {
    firstFont: string;
    secondFont: string;
    lato: string;
    seasons: string;
  };
  borderRadius: {
    br4: string;
    br8: string;
  };
}

export const theme: ThemeInterface = {
  primaryColor: {
    shadePurple: '#255573',
    royalBlue: '#3790B3',
    brightCyan: '#34F5FC',
    twilightBlue: '#163345',
    sereneSkyBlue: '#d3e5f5',
    gentleAzure: '#d3e5f5',
    pristineSnowfall: '#ffffff',
    midnightInk: '#001e2f',
    duskHarbor: '#0f222e',
    slateShadow: '#555',
    silkenMoonlight: '#f3f4f6',
    lunarElegance: '#e5e7eb',
    lightGray: '#eae9e9',
    red: '#d32f2f',
    lightRed: '#FF7F7F',
    warningRed: '#feecdc',
    blue: 'blue',
    green: '#90EE90',
    fluentGray: '#47728c',
    denseGray: '#d0e4f0',
    lightBlue: '#fdf2f2',
    cornflowerBlue: '#6060f9',
    borderBlue: '#d7e9f0',
    fluentBlue: '#0d78a2',
    melishBlue: '#62a0b3',
    white: 'white',
    whiteSmoke: '#F8F8F8',
    black: '#000000',
    steelBlue: '#228fb4',
    deepCerulean: '#09769b',
    mutedGray: '#4B5563',
    lightGray2: '#D1D5DB',
    softBlue: '#CCD0E5',
    darkYellow: '#c17f00',
    lightPink: '#FFC0CB',
    aquamarine: '#BCF0DA',
    royalGray: '#6b7280',
    navyBlue: '#00137e',
    sereneSky: '#AEC7D7',
    aquaBlue: '#8cabbe',
    brandPaletteGray: '#374151',
    mintCream: '#EFFFFC',
    peach: '#ffecb4',
    vanilla: '#FFF8F1',
    burntSienna: '#8A2C0D',
    grayish: '#888',
    deepSeaBlue: '#1e445c',
    cream: '#fff8eb',
    brandPalette: '#AEC7D7',
    cyanGreen: '#15676A',
    lightGreen: '#03543F',
    successGreen: '#DEF7EC',
    powderBlue: '#D0E4F0',
    darkBlue: '#4B637D',
    aliceBlue: '#F8FCFF',
    creamColor: '#FFFCF6',
    aqua: '#f3faf7',
    coral: '#FFE8BD',
    snow: '#F9FAFB',
    oceanicTeal: '#47728C',
    midnight: '#0F222E',
    babyBlue: '#ebf4f7',
    azure: '#ebf7ff',
    midNightBlue: '#0a3739',
    midYellow: '#723B13',
    brandPaletteRed: '#FBD5D5',
    brandPaletteOrange: '#FCD9BD',
    skyBlue: '#EBF7FF',
    GlacierMist: '#EBFEFF',
    lightYellow: '#ffdd99',
    lightGrey: '#F3F4F6',
    blastYellow: '#fcb326',
  },

  // fontSize: new Map([
  //   ['3xs', 'clamp(0.64rem, calc(0.58rem + 0.06vw), 0.76rem)'],
  //   ['2xs', 'clamp(0.70rem, calc(0.67rem + 0.12vw), 0.81rem)'],
  //   ['xs', 'clamp(0.76rem, calc(0.75rem + 0.06vw), 0.85rem)'],
  //   ['s', 'clamp(0.88rem, calc(0.83rem + 0.21vw), 1.17rem)'],
  //   ['m', 'clamp(1.01rem, calc(0.92rem + 0.43vw), 1.61rem)'],
  //   ['l', 'clamp(1.16rem, calc(1.00rem + 0.76vw), 2.22rem)'],
  //   ['xl', 'clamp(1.33rem, calc(1.08rem + 1.24vw), 3.07rem)'],
  //   ['2xl', 'clamp(1.53rem, calc(1.14rem + 1.93vw), 4.23rem)'],
  //   ['3xl', 'clamp(1.76rem, calc(1.18rem + 2.91vw), 5.84rem)'],
  //   ['4xl', 'clamp(2.02rem, calc(1.16rem + 4.31vw), 8.06rem)'],
  //   ['5xl', 'clamp(2.33rem, calc(1.07rem + 6.28vw), 11.12rem)'],
  // ]),

  fontSize: new Map([
    ['2xs', 'clamp(0.576rem, 0.7444rem + -0.2106vw, 0.7023rem)'], // Size -3: 11.2373px → 9.216px
    ['xs', 'clamp(0.72rem, 0.8135rem + -0.1169vw, 0.7901rem)'], // Size -2: 12.642px → 11.52px
    ['s', 'clamp(0.8889rem, 0.8852rem + 0.0185vw, 0.9rem)'], // Size -1: 14.2222px → 14.4px
    ['m', 'calc(clamp(1rem, 0.9583rem + 0.2083vw, 1.125rem) - 2px)'], // Size 0: 16px → 18px
    ['l', 'calc(clamp(1.125rem, 1.0313rem + 0.4688vw, 1.4063rem) - 2.5px)'], // Size 1: 18px → 22.5px
    ['xl', 'calc(clamp(1.2656rem, 1.1016rem + 0.8203vw, 1.7578rem) - 3px)'], // Size 2: 20.25px → 28.125px
    ['2xl', 'calc(clamp(1.4238rem, 1.166rem + 1.2891vw, 2.1973rem) - 3.5px)'], // Size 3: 22.7813px → 35.1563px
    ['3xl', 'calc(clamp(1.6018rem, 1.2202rem + 1.908vw, 2.7466rem) - 4px)'], // Size 4: 25.6289px → 43.9453px
    ['4xl', 'calc(clamp(1.802rem, 1.2583rem + 2.7187vw, 3.4332rem) - 4.5px)'], // Size 5: 28.8325px → 54.9316px
    ['5xl', 'clamp(2.03rem, calc(1.07rem + 6.28vw), 11.12rem)'], // Custom size, keeping original for larger needs
  ]),

  space,

  fontFamily: {
    firstFont: 'Lato',
    secondFont: 'the-seasons',
    lato: 'Lato',
    seasons: 'the-seasons',
  },

  borderRadius: {
    br4: '0.25rem',
    br8: '0.5rem',
  },
};

export default theme;
