import { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

import { IconButton } from '@mui/material';

import { getEventHistory } from '@src/api/event';
import { deletePackagesEvent } from '@src/api/package';
import DeleteModal from '@src/components/DeleteModal';
import { DeleteIcon, MenuBoard } from '@src/components/Icons';
import MaxWidthContainer from '@src/components/MaxWidthContainer/MaxWidthContainer';
import { Date as DateType } from '@src/components/Modals/EventDetailModal';
import { FilterSelectOptions } from '@src/components/Modals/FilterModal';
import ServiceTypeModal from '@src/components/Modals/ServiceTypeModal';
import NoDataFound from '@src/components/NoDataFound';
import OrderHistoryHelmet from '@src/components/OrderHistoryHelmet';
import OrderHistoryTable from '@src/components/OrderHistoryTable';
import { useFetchPackages } from '@src/hooks/useFetchPackages';
import {
  btnToColorMapper,
  BUFFET,
  DEFAULT_SERVICE_TYPE,
  DROP_OFF,
  NO_DATA_MESSAGE,
  NO_FILTER_RESULTS_MATCH,
  NO_RESULTS_MATCH,
  orderStatusList,
  paymentStatusList,
  paymentToColorMapper,
  serviceType,
  UiRoutes,
} from '@src/lib/constants';
import { getOptions, Options } from '@src/lib/getOptions';
import { getUpdateBody } from '@src/lib/getUpdate';
import { capitalizeFirstLetter, dateFormat, formatAmount, formatDates, getMinimumCount } from '@src/lib/helper';
import { TableData } from '@src/lib/types';
import { Event, OrderStatus, PaymentStatus } from '@src/model/Event';
import { Package } from '@src/model/packages';
import { clearEventDetails } from '@src/redux/action/eventDetails';
import { clearInvoiceDetails } from '@src/redux/action/invoiceDetails';
import { notInOrderJourney } from '@src/redux/action/journeyStatus';
import { startLoaderAction, stopLoaderAction } from '@src/redux/action/login';
import { ReduxProps } from '@src/redux/type';

import {
  buttonStyle,
  StyledHelmentContainer,
  StyledPaymentStatusBtn,
  StyledStatusBtn,
  StyledTableContainer,
} from './style';

interface FilterValue {
  label: string;
  value: string | boolean;
}

interface FilterOptions {
  label: string;
  key: string;
  isMulti?: boolean;
  value: FilterValue[];
  isDate?: boolean;
}

const tableHeaders = (
  tableData: TableData[],
  setIsModalOpen: (isOpen: boolean) => void,
  entryRef: React.MutableRefObject<TableData | null>
) => [
  {
    name: 'bookedAt',
    label: 'Order Date',
    options: {
      sort: true,
      customBodyRender: (value: string) => {
        return value !== null ? dateFormat(value) : '-';
      },
    },
  },
  { name: 'orderNumber', label: 'Order ID', options: { sort: false } },
  {
    name: 'packageDetails',
    label: 'Service Type',
    options: {
      sort: false,
      customBodyRender: (value: any) => {
        return value?.service === 'buffet'
          ? 'Full Service Buffet Catering'
          : value?.service === 'drop-off'
          ? 'Drop-off Catering'
          : '-';
      },
    },
  },
  {
    name: 'customerId',
    label: 'Customer Name',
    options: {
      sort: false,
      customBodyRender: (value: { firstName: string; lastName: string }) => {
        const name = `${value?.firstName || ''} ${value?.lastName || ''}`;
        return name === ' ' ? '-' : name;
      },
    },
  },
  {
    name: 'eventDates',
    label: 'Event Date',
    options: {
      sort: false,
      customBodyRender: (value: any) => {
        return formatDates(value?.startDate, value?.endDate);
      },
    },
  },
  {
    name: 'numberOfPerson',
    label: 'Guests',
    options: {
      sort: false,
      customBodyRender: (value: number) => {
        return value ?? '-';
      },
    },
  },
  {
    name: 'orderId',
    label: 'Total Amount',
    options: {
      sort: false,
      customBodyRender: (value: any) => {
        return formatAmount(value?.finalAmount) ?? '-';
      },
    },
  },
  {
    name: 'orderStatus',
    label: 'Order Status',
    options: {
      sort: false,
      customBodyRender: (value: OrderStatus) => {
        return (
          <StyledStatusBtn color={btnToColorMapper[value?.name]}>{capitalizeFirstLetter(value?.name)}</StyledStatusBtn>
        );
      },
    },
  },
  {
    name: 'paymentStatus',
    label: 'Payment Status',
    options: {
      sort: false,
      customBodyRender: (value: PaymentStatus) => {
        return (
          <StyledPaymentStatusBtn color={paymentToColorMapper[value?.name]}>
            {capitalizeFirstLetter(value?.name)}
          </StyledPaymentStatusBtn>
        );
      },
    },
  },
  {
    name: 'action',
    label: 'Action',
    options: {
      sort: false,
      customBodyRenderLite: (dataIndex: number) => {
        const orderStatus = tableData[dataIndex]?.orderStatus;

        if (typeof orderStatus === 'object' && orderStatus !== null && 'name' in orderStatus) {
          return orderStatus.name === 'DRAFT' ? (
            <IconButton
              onClick={event => {
                event.stopPropagation();
                setIsModalOpen(true);
                entryRef.current = tableData[dataIndex];
              }}
              sx={buttonStyle}
            >
              <DeleteIcon />
            </IconButton>
          ) : (
            '-'
          );
        }
      },
    },
  },
];

const filterOptions: FilterOptions[] = [
  {
    label: 'Service Type',
    key: 'serviceType',
    value: [
      { label: 'Full Service Buffet Catering', value: 'buffet' },
      { label: 'Drop-off Catering', value: 'drop-off' },
    ],
  },
  {
    label: 'Order Status',
    key: 'orderStatus',
    value: [
      ...(orderStatusList || []).map(({ name, id }) => {
        return { label: name, value: id };
      }),
    ],
  },
  {
    label: 'Payment Status',
    key: 'paymentStatus',
    value: [
      ...(paymentStatusList || []).map(({ name, id }) => {
        return { label: name, value: id };
      }),
    ],
  },
  {
    label: 'Date',
    key: 'eventDates',
    value: [{ label: 'Date', value: '' }],
    isDate: true,
  },
];

export const orderSummaryStatus = ['REVIEWED', 'CONFIRMED', 'QUOTE', 'COMPLETED', 'CANCELLED'];

const OrderHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [sortBy, setSortBy] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterValue, setFilterValue] = useState<FilterSelectOptions>({});
  const [count, setCount] = useState(0);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [date, setDate] = useState<DateType>({ startDate: '', endDate: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const entryRef = useRef<TableData | null>(null);
  const [isServiceTypeModalOpen, setIsServiceTypeModalOpen] = useState(false);

  const { _id: userId } = useSelector((state: ReduxProps) => state?.loginReducer?.loginState);

  const ctaButton = {
    text: 'Create Order',
    icon: <MenuBoard />,
    handleCTA: function () {
      setIsServiceTypeModalOpen(!isServiceTypeModalOpen);
    },
  };

  // const { data: packagesData } = useFetchPackages<Package[]>({ serviceType: [DROP_OFF, DEFAULT_SERVICE_TYPE] });
  // const minimumCounts = useMemo(() => getMinimumCount(packagesData), [packagesData]);

  const handleSelect = (id: string) => {
    navigate(id === BUFFET ? UiRoutes?.PACKAGESFS : UiRoutes?.DROP_OFF_CHOOSE_MEAL);
  };

  const fetchData = async () => {
    const loaderRequest = `unique_${Date.now()}`;

    try {
      dispatch(startLoaderAction(loaderRequest));

      const options: Options = {
        limit: rowsPerPage,
        page: page + 1,
        orderBy: sortBy,
      };

      if (searchTerm) {
        options['search'] = { text: searchTerm, fieldsToBeSearched: ['orderNumber'] };
      }

      if (filterValue) {
        options['filter'] = [];

        if (filterValue?.serviceType) {
          options['filter'].push({ key: 'serviceType', value: filterValue?.serviceType });
        }

        if (filterValue?.paymentStatus) {
          options['filter'].push({ key: 'paymentStatus', value: filterValue?.paymentStatus });
        }

        if (filterValue?.orderStatus) {
          options['filter'].push({ key: 'orderStatus', value: filterValue?.orderStatus });
        }

        if (filterValue?.startDate !== 'Invalid date') {
          options['filter'].push({ key: 'startDate', value: filterValue?.startDate });
        }

        if (filterValue?.endDate !== 'Invalid date') {
          options['filter'].push({ key: 'endDate', value: filterValue?.endDate });
        }
      }

      const params = getOptions(options);
      const response = await getEventHistory(params);

      response?.data?.results && setTableData(response?.data?.results);
      response?.data?.totalResults && setCount(response?.data?.totalResults);
      dispatch(notInOrderJourney());
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(stopLoaderAction(loaderRequest));
    }
  };

  useEffect(() => {
    dispatch(clearEventDetails());
    dispatch(clearInvoiceDetails());
  }, []);

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, searchTerm, sortBy]);

  const onSearch = (text: string) => {
    setSearchTerm(text?.trim());
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  const handleFilter = () => {
    fetchData();
  };

  const clearFilter = useCallback(() => {
    setFilterValue({});
    setDate({ startDate: '', endDate: '' });
    fetchData();
  }, [setFilterValue]);

  const handleClick = (data: unknown, row: { dataIndex: number }) => {
    const eventId = tableData[row?.dataIndex]?._id;
    const orderStatus = tableData[row?.dataIndex]?.orderStatus;

    const selectedUser = tableData[row?.dataIndex]?.customerId?._id;
    const serviceType = tableData[row?.dataIndex]?.packageDetails?.service;
    const makeMyOwn = tableData[row?.dataIndex]?.makeMyOwn;

    if (typeof orderStatus === 'object' && 'name' in orderStatus) {
      const status = orderStatus?.name as string;

      if (selectedUser === userId) {
        if (orderSummaryStatus.includes(status)) {
          navigate(UiRoutes.ORDER_SUMMARY?.replace(':id', `${eventId}`));
        } else {
          if (serviceType === DROP_OFF && makeMyOwn) {
            navigate(UiRoutes.DROP_OFF_CHOOSE_MEAL.concat(`?eventId=${eventId}`));
          } else {
            navigate(UiRoutes.ORDER_DETAILS.replace(':eventId', `${eventId}`));
          }
        }
      }
      // else {
      //   throw new Error('User not authorized to view this order');
      // }
    }
  };

  const handleTableChange = (
    action: string,
    tableState: {
      page: SetStateAction<number>;
      rowsPerPage: React.SetStateAction<number>;
      sort?: { order: 'asc' | 'desc'; column: string };
    }
  ) => {
    if (action === 'changePage') {
      setPage(tableState?.page);
    } else if (action === 'changeRowsPerPage') {
      setRowsPerPage(tableState?.rowsPerPage);
      setPage(0);
    }
  };

  const handleDeleteEvent = async () => {
    const loaderRequest = `unique_${Date.now()}`;

    try {
      dispatch(startLoaderAction(loaderRequest));

      if (entryRef.current !== null) {
        await deletePackagesEvent(getUpdateBody({ id: entryRef.current?._id as string }));
        enqueueSnackbar('Entry deleted successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
        fetchData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(stopLoaderAction(loaderRequest));
    }
  };

  return (
    <>
      <StyledHelmentContainer>
        <MaxWidthContainer>
          <OrderHistoryHelmet
            date={date}
            setDate={setDate}
            subHeader={'Order History'}
            ctaButton={ctaButton}
            search={{
              onSearch: text => onSearch(text),
              onClearSearch: clearSearch,
            }}
            filters={{
              handleFilter: handleFilter,
              filterOptions,
              clearFilter: clearFilter,
              defaultFilter: filterValue,
              setFilterValue,
            }}
          />
        </MaxWidthContainer>
      </StyledHelmentContainer>
      <StyledTableContainer>
        {count && tableData?.length ? (
          <MaxWidthContainer>
            <OrderHistoryTable
              headers={tableHeaders(tableData, setIsModalOpen, entryRef)}
              data={tableData}
              handleTableChange={handleTableChange}
              rowsPerPage={rowsPerPage}
              page={page}
              count={count}
              handleRowClick={handleClick}
              setSortBy={setSortBy}
            />
          </MaxWidthContainer>
        ) : (
          <NoDataFound
            message={
              searchTerm?.trim()
                ? NO_RESULTS_MATCH
                : count && !tableData?.length
                ? NO_FILTER_RESULTS_MATCH
                : NO_DATA_MESSAGE
            }
          />
        )}
      </StyledTableContainer>
      {isModalOpen && (
        <DeleteModal
          isOpen={isModalOpen}
          modalName={'Are you sure you want to delete this entry ?'}
          onRequestClose={() => {
            setIsModalOpen(false);
          }}
          onSave={() => {
            handleDeleteEvent();
          }}
        />
      )}
      {
        <ServiceTypeModal
          serviceType={serviceType([20, 500])}
          isOpen={isServiceTypeModalOpen}
          onRequestClose={() => {
            setIsServiceTypeModalOpen(false);
          }}
          onSave={id => {
            handleSelect(id);
          }}
        />
      }
    </>
  );
};

export default OrderHistory;
