import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { saveUser } from '@src/api/login';
import useAuthentication from '@src/hooks/useAuthentication';
import {
  BUFFET,
  DROP_OFF,
  loggedInHeaderTabs,
  moreItems,
  statusCodes,
  tabs,
  UiRoutes,
  WORDPRESS_BASE_URL,
  wordpressRoutes,
} from '@src/lib/constants';
import { getTotalAmount } from '@src/lib/helper';
import { blastLogo, toggleNavbar } from '@src/lib/imgUrl';
import { clearEventDetails } from '@src/redux/action/eventDetails';
import { clearInvoiceDetails } from '@src/redux/action/invoiceDetails';
import { clearJourney } from '@src/redux/action/journeyStatus';
import { clearUserData, logoutAction, startLoaderAction, stopLoaderAction } from '@src/redux/action/login';
import { LoginStateProps, ReduxProps } from '@src/redux/type';

import DeleteModal from '../DeleteModal';
import MaxWidthContainer from '../MaxWidthContainer/MaxWidthContainer';
import OtpVerificationModal from '../Modals/OtpVerificationModal';
import SaveProgressModal from '../Modals/SaveProgressModal';
import PromotionalBanner from '../PromotionalBanner';

import ProfileComponent from './profileComponent';
import {
  DefaultProfileIcon,
  GlobalStyle,
  StyledBlastLogoContainer,
  StyledCloseButton,
  StyledContact,
  StyledCustomLink,
  StyledDropdownMenu,
  StyledDropdownMenuItem,
  StyledDropdownMenuWrapper,
  StyledHamburgerIcon,
  StyledHamburgerImage,
  StyledHeaderContainer,
  StyledImage,
  StyledLoginButton,
  StyledLoginButtonContainer,
  StyledMoreButton,
  StyledMoreOptionsDropdown,
  StyledMoreOptionsItem,
  StyledNavTabs,
  StyledOuter,
} from './styles';

interface TabItemProps {
  text: string;
  link: string;
  handleClick: (link: string) => void;
}

const TabItem: React.FC<TabItemProps> = ({ text, link, handleClick }) => {
  return (
    <StyledCustomLink onClick={() => handleClick(link)} key={text}>
      {text}
    </StyledCustomLink>
  );
};

const Header = ({
  isSticky,
  isLoggedIn,
  headerBackGround,
}: {
  isSticky?: boolean;
  isLoggedIn?: boolean;
  headerBackGround?: string;
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { inOrderJourney } = useSelector((state: ReduxProps) => state?.journeyStatusReducer);
  const [showModal, setShowModal] = useState(false);
  const [redirectLink, setRedirectLink] = useState('');
  const [isLogout, setIsLogout] = useState(false);
  const [isCartEmpty, setIsCartEmpty] = useState(true);

  const params = useSearchParams();
  const _eventId = params[0].get('eventId');

  const {
    firstName: userFirstName,
    isUserAuthenticated,
    phoneNumber: userPhoneNumber,
    // email: userEmail,
    //TODO: need when login with email
  } = useSelector((state: { loginReducer: { loginState: LoginStateProps } }) => state?.loginReducer?.loginState);

  const { _id, makeMyOwn, packageDetails } = useSelector((state: ReduxProps) => state?.eventDetailsReducer);
  const defaultBooking = useSelector((state: ReduxProps) => state?.bookingDetailsReducer?.booking);

  const toggleMenuVisible = () => setMenuVisible(prev => !prev);
  const toggleShowMore = () => setShowMore(prev => !prev);

  const {
    setIsLoginModalOpen,
    isLoginModalOpen,
    isOtpModalOpen,
    otpModalProps,
    headingOutModalProps,
    phoneNumber,
    apiMessages,
    setApiMessages,
    // triggerUpdate,
    // setTriggerUpdate,
  } = useAuthentication();

  const handleHeadingOutVerify = useCallback(
    async (otp?: string) => {
      const loaderRequest = `unique_${Date.now()}`;
      // console.log('Initial Trigger State:', triggerUpdate);
      // console.log('Setting update trigger');
      // console.log('defaultBooking', defaultBooking);
      // setTriggerUpdate(true);

      // try {
      //   dispatch(startLoaderAction(loaderRequest));

      //   const headingOutData = {
      //     ...(otp ? { otp: otp, phoneNumber } : { phoneNumber: userPhoneNumber }),
      //     // ...(otp ? { otp: otp, email } : { email: userEmail }),
      //     //TODO: need for email login flow
      //     orderUrl:
      //       packageDetails.service === BUFFET
      //         ? process.env.REACT_APP_BASE_URL + UiRoutes.ORDER_DETAILS.replace(':eventId', _id ?? '')
      //         : process.env.REACT_APP_BASE_URL + UiRoutes.DROP_OFF_CHOOSE_MEAL.concat(`?eventId=${_id}`),
      //     eventId: _id,
      //   };

      //   const verifyResult = await saveUser(headingOutData);

      //   if (verifyResult?.status === statusCodes?.API_SUCCESS_CODE) {
      //     if (otp) {
      //       enqueueSnackbar(
      //         'Your order has been saved successfully! You can access your order anytime by logging in with your mobile number.',
      //         {
      //           variant: 'success',
      //           autoHideDuration: 3000,
      //           onClose: () => {
      //             window.location.href = redirectLink ?? WORDPRESS_BASE_URL ?? '#';
      //           },
      //         }
      //       );
      //     } else {
      //       window.location.href = redirectLink ?? WORDPRESS_BASE_URL ?? '#';
      //     }
      //   }
      // } catch (error: any) {
      //   if (error?.response?.data?.errorCode === 'EXCEEDED/MATCH') {
      //     otpModalProps?.setOtpState({ ...otpModalProps?.otpState, countdown: 0 });
      //   }

      //   setApiMessages({ ...apiMessages, errorMessage: error.response.data.message, successMessage: '' });
      // } finally {
      //   dispatch(stopLoaderAction(loaderRequest));
      // }
    },
    // [email, _id, isUserAuthenticated, redirectLink, WORDPRESS_BASE_URL, userEmail, apiMessages]
    [phoneNumber, _id, isUserAuthenticated, redirectLink, WORDPRESS_BASE_URL, userPhoneNumber, apiMessages]
    //TODO: need for email login flow
  );

  const headingOutOtpProps = {
    ...otpModalProps,
    onSave: handleHeadingOutVerify,
    message: `We’ve sent a One-Time Password (OTP) to your mobile number ending with  ***${phoneNumber.slice(
      -3
    )}. Please input the OTP provided to verify your identity and proceed with saving for later.`,
    // message: `We’ve sent a One-Time Password (OTP) to your email at ${obscureInput(
    //   email
    // )}. Please input the OTP provided to verify your identity and proceed with saving for later.`,
  };

  const dropOffEditMode = packageDetails.service === DROP_OFF && !!_eventId;

  const getBookingTotal = () => {
    if (packageDetails.service === DROP_OFF) {
      return getTotalAmount(defaultBooking?.length > 0 ? defaultBooking : [], true);
    }

    return getTotalAmount(defaultBooking, makeMyOwn);
  };

  const bookingTotal = getBookingTotal();

  useEffect(() => {
    if (defaultBooking && defaultBooking?.[0]?.dishes?.length > 0 && bookingTotal > 0) {
      setIsCartEmpty(false);
    } else setIsCartEmpty(true);
  }, [defaultBooking, bookingTotal]);

  const handleClick = (link: string) => {
    if (inOrderJourney) {
      setRedirectLink(link);

      if (isCartEmpty && !dropOffEditMode) {
        if (!isUserAuthenticated) {
          dispatch(clearEventDetails());
          dispatch(clearInvoiceDetails());
          dispatch(clearJourney());
        }

        window.location.href = link ?? '#';
      } else {
        if (isUserAuthenticated) {
          setShowModal(true);
        } else {
          setIsLoginModalOpen(true);
        }
      }
    } else {
      window.location.href = link ?? '#';
    }
  };

  const handleLogoutClick = (link: string) => {
    if (inOrderJourney) {
      setRedirectLink(link);
      setIsLoginModalOpen(true);
    } else {
      window.location.href = link ?? '#';
    }
  };

  const handleEditModalClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (isLoginModalOpen || isOtpModalOpen) {
      setApiMessages({
        successMessage: '',
        errorMessage: '',
      });
    }
  }, [isLoginModalOpen, isOtpModalOpen]);

  useEffect(() => {
    const bodyClass = 'activeDropdown';

    if (menuVisible) {
      document.body.classList.add(bodyClass);
    } else {
      document.body.classList.remove(bodyClass);
    }

    return () => document.body.classList.remove(bodyClass);
  }, [menuVisible]);

  const renderLoggedInTabs = () =>
    loggedInHeaderTabs.map(tab => (
      <TabItem key={tab.text} text={tab.text} link={tab.link || '#'} handleClick={handleClick} />
    ));

  const renderTabs = () =>
    tabs.map(tab => <TabItem key={tab.text} text={tab.text} link={tab.link || '#'} handleClick={handleClick} />);

  const renderMoreItems = () =>
    moreItems.map(({ text, link }) => (
      <StyledCustomLink onClick={() => handleClick(link)} key={text}>
        <StyledMoreOptionsItem>{text}</StyledMoreOptionsItem>
      </StyledCustomLink>
    ));

  const renderMenuIcon = () =>
    isLoggedIn ? (
      <DefaultProfileIcon>{userFirstName?.charAt(0) ?? 'G'}</DefaultProfileIcon>
    ) : (
      <StyledHamburgerImage src={toggleNavbar} alt='Toggle Navbar' />
    );

  const performLogoutActions = () => {
    dispatch(clearEventDetails());
    dispatch(logoutAction());
    dispatch(clearUserData());
    dispatch(clearInvoiceDetails());
    dispatch(clearJourney());
  };

  const handleLogout = () => {
    setIsLogout(true);

    if (inOrderJourney) {
      setShowModal(true);
    } else {
      performLogoutActions();
      // navigate(UiRoutes.HOME);
      navigate(UiRoutes.PACKAGESFS);
    }
  };

  return (
    <>
      <StyledOuter $isSticky={isSticky}>
        <GlobalStyle />
        <PromotionalBanner />
        <StyledHeaderContainer $headerBackGround={headerBackGround}>
          <MaxWidthContainer extraStyle={{ justifyContent: 'space-between' }}>
            <StyledBlastLogoContainer>
              {/* <StyledCustomLink onClick={() => handleClick(WORDPRESS_BASE_URL ?? UiRoutes.HOME)}> */}
              <StyledCustomLink onClick={() => handleClick(WORDPRESS_BASE_URL ?? UiRoutes.PACKAGESFS)}>
                <StyledImage src={blastLogo} alt='Blast Logo' />
              </StyledCustomLink>

              {isLoggedIn && <StyledNavTabs>{renderLoggedInTabs()}</StyledNavTabs>}
            </StyledBlastLogoContainer>
            <StyledHamburgerIcon onClick={toggleMenuVisible}>
              {menuVisible ? <StyledCloseButton>X</StyledCloseButton> : renderMenuIcon()}
            </StyledHamburgerIcon>
            {!isLoggedIn && (
              <StyledNavTabs onMouseLeave={() => setShowMore(true)}>
                {renderTabs()}
                <StyledDropdownMenuItem>
                  <StyledMoreButton show={showMore} onClick={toggleShowMore}>
                    More {showMore ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                  </StyledMoreButton>
                  {!showMore && <StyledMoreOptionsDropdown>{renderMoreItems()}</StyledMoreOptionsDropdown>}
                </StyledDropdownMenuItem>
              </StyledNavTabs>
            )}
            {isLoggedIn ? (
              <ProfileComponent />
            ) : (
              <StyledLoginButtonContainer>
                <StyledContact
                  onClick={() => {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: 'click_contact',
                    });
                    window.location.href = WORDPRESS_BASE_URL + wordpressRoutes.CONTACT_US;
                  }}
                >
                  Contact Us
                </StyledContact>
                <StyledLoginButton onClick={() => handleLogoutClick(UiRoutes.LOGIN)}>Login</StyledLoginButton>
              </StyledLoginButtonContainer>
            )}
            {menuVisible && (
              <StyledDropdownMenuWrapper isVisible={menuVisible}>
                <StyledDropdownMenu>
                  {isLoggedIn ? (
                    <>
                      {renderLoggedInTabs()}
                      <StyledLoginButton onClick={() => handleLogout()} $mobileView>
                        Logout
                      </StyledLoginButton>
                    </>
                  ) : (
                    <>
                      {renderTabs()}
                      <StyledDropdownMenuItem>
                        <StyledMoreButton show={showMore} onClick={toggleShowMore}>
                          More {showMore ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                        </StyledMoreButton>
                        {!showMore && <StyledMoreOptionsDropdown>{renderMoreItems()}</StyledMoreOptionsDropdown>}
                      </StyledDropdownMenuItem>
                      <StyledLoginButton onClick={() => handleLogoutClick(UiRoutes.LOGIN)} $mobileView>
                        Login
                      </StyledLoginButton>
                    </>
                  )}
                </StyledDropdownMenu>
              </StyledDropdownMenuWrapper>
            )}
          </MaxWidthContainer>
        </StyledHeaderContainer>
      </StyledOuter>
      <OtpVerificationModal {...headingOutOtpProps} />
      {isUserAuthenticated ? (
        <DeleteModal
          isOpen={showModal}
          modalName='Are you sure you want to exit? Your progress will be saved.'
          onRequestClose={handleEditModalClose}
          onSave={handleHeadingOutVerify}
          isLogoutAction={isLogout}
          performLogoutActions={performLogoutActions}
        />
      ) : (
        <SaveProgressModal {...headingOutModalProps} />
      )}
    </>
  );
};

export default Header;
