import styled from 'styled-components';

import { Breakpoints } from '@src/styles/breakpoints';

export const StyledContainer = styled.form`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 40px;
  background-color: white;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  &:focus {
    outline: none;
  }
  ${Breakpoints.TABLET} {
    width: 75vw;
    padding: 20px;
  }
  ${Breakpoints.TABLET_SMALL} {
    height: 80vh;
  }
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: center;

  justify-content: space-between;
  width: 100%;
`;

export const StyledHeading = styled.div`
  color: ${({ theme }) => theme.primaryColor.twilightBlue};
  font-family: 'the-seasons', Lato;
  font-size: 24px;
  font-weight: 600;
  ${Breakpoints.MOBILE} {
    font-size: 20px;
    text-wrap: wrap;
  }
`;

export const StyledClose = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const StyledCardContainer = styled.div`
  display: flex;
  gap: 24px;
  overflow: auto;
  ${Breakpoints.TABLET_SMALL} {
    flex-direction: column;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledCardName = styled.div`
  color: ${({ theme }) => theme.primaryColor.twilightBlue};
  font-family: 'the-seasons', Lato;
  font-size: 20px;
  font-weight: 600;
  ${Breakpoints.MOBILE} {
    font-size: ${({ theme }) => theme.fontSize.get('s')};
  }
`;

export const StyledCard = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 38px;
  border: 3px solid ${({ theme }) => theme.primaryColor.lunarElegance};
  &:hover {
    background-color: ${({ theme }) => theme.primaryColor.azure};
    border: 3px solid ${({ theme }) => theme.primaryColor.royalBlue};
    ${StyledCardName} {
      color: ${({ theme }) => theme.primaryColor.fluentBlue};
    }
  }
  border-radius: 4px;
  cursor: pointer;
`;

export const StyledCardIcon = styled.img`
  width: 66px;
  height: 72px;
  ${Breakpoints.MOBILE} {
    width: 100%;
  }
`;
export const StyledCardContent = styled.div`
  color: ${({ theme }) => theme.primaryColor.royalGray};
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  align-self: stretch;
  ${Breakpoints.MOBILE} {
    font-size: ${({ theme }) => theme.fontSize.get('s')};
  }
`;

export const StyledQuantityContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledImage = styled.img``;

export const StyledLabel = styled.div`
  display: flex;
  color: ${({ theme }) => theme.primaryColor.shadePurple};
  font-weight: 400;
  text-wrap: nowrap;
`;

export const StyledCount = styled.div`
  margin-right: 4px;
  font-weight: 700;
`;
