import styled, { css, CSSProp } from 'styled-components';

import { Breakpoints } from './breakpoints';

export const StyledMainWrapper = styled.div<{ $bgColor?: string }>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 calc(${({ theme }) => theme.space.get('2xl')}*3.1);
  ${({ $bgColor }) => ($bgColor ? `background-color:${$bgColor};` : ``)}
  min-height: 100vh;
  ${Breakpoints.DESKTOP} {
    padding: 0 calc(${({ theme }) => theme.space.get('2xl')}*2);
  }
  ${Breakpoints.DESKTOP_SMALL} {
    padding: 0 ${({ theme }) => theme.space.get('s')};
  }
`;

export const StyledFullWidthWrapper = styled.div`
  margin: 0 calc(${({ theme }) => theme.space.get('2xl')}*-3.1);

  ${Breakpoints.DESKTOP} {
    margin: 0 calc(${({ theme }) => theme.space.get('2xl')}*-2);
  }
  ${Breakpoints.DESKTOP_SMALL} {
    margin: 0 calc(${({ theme }) => theme.space.get('s')}*-1);
  }
`;

interface StyledSectionWrapperProps {
  extraStyles?: CSSProp;
}

export const StyledSectionWrapper = styled.section<StyledSectionWrapperProps>`
  padding: calc(${({ theme }) => theme.space.get('xl')} + 0.75rem) 0;
  width: 100%;
  overflow: hidden;

  ${Breakpoints.TABLET} {
    padding: calc(${({ theme }) => theme.space.get('m')} + 0.75rem) 0;
  }

  ${({ extraStyles }) =>
    extraStyles &&
    css`
      ${extraStyles}
    `}
`;
