import Modal from '@mui/material/Modal';

import { closeIcon, peopleOutline } from '@src/lib/imgUrl';
import { StyledSection } from '@src/screens/Login/style';

import {
  StyledCard,
  StyledCardContainer,
  StyledCardContent,
  StyledCardIcon,
  StyledCardName,
  StyledClose,
  StyledContainer,
  StyledCount,
  StyledHeaderContainer,
  StyledHeading,
  StyledImage,
  StyledLabel,
  StyledQuantityContainer,
} from './styles';

interface ServiceTypeProps {
  id: string;
  name: string;
  content: string;
  icon?: string;
  label: string;
  minimumCount?: number | null;
}

interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onSave: (id: string) => void;
  serviceType?: ServiceTypeProps[];
}

const ServiceTypeModal: React.FC<ModalProps> = ({ isOpen, onRequestClose, onSave, serviceType }) => {
  const handleSave = (id: string) => {
    onSave(id);
    handleClose();
  };

  const handleClose = () => {
    onRequestClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          backdropFilter: 'blur(7px)',
        },
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <StyledContainer>
        <StyledHeaderContainer>
          <StyledHeading>Choose your catering service</StyledHeading>
          <StyledClose src={closeIcon} onClick={handleClose}></StyledClose>
        </StyledHeaderContainer>
        <StyledCardContainer>
          {serviceType &&
            serviceType.map(item => (
              <StyledCard key={item?.id} onClick={() => handleSave(item?.id)}>
                <StyledCardIcon src={item?.icon} alt={`${item?.id}-icon`} />
                <StyledSection $gap='24px'>
                  <StyledSection $gap='8px'>
                    <StyledCardName>{item?.name}</StyledCardName>
                    <StyledCardContent>{item?.content}</StyledCardContent>
                  </StyledSection>
                  <StyledQuantityContainer>
                    <StyledImage src={peopleOutline} alt='icon' />
                    <StyledLabel>
                      <StyledCount>{item?.minimumCount || 20}</StyledCount>
                      {` ${item.label}`}
                    </StyledLabel>
                  </StyledQuantityContainer>
                </StyledSection>
              </StyledCard>
            ))}
        </StyledCardContainer>
      </StyledContainer>
    </Modal>
  );
};

export default ServiceTypeModal;
